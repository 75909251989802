@tailwind base;
@tailwind components;
@tailwind utilities;

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}
.floating {
  animation: float 6s ease-in-out infinite;
}
.gradient-text {
  background: linear-gradient(90deg, #55ec58, #4dffdb);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.process-step {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}
.process-step.visible {
  opacity: 1;
  transform: translateY(0);
}
.service-card {
  transition: transform 0.3s ease;
}
.service-card:hover {
  transform: translateY(-5px);
}
